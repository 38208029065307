import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Text from 'ui-kit/text/text';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import {
    accountAllSmsNumbersSelector,
    accountNotificationsSelector,
    accountSmsCancelingReqeustSelector
} from 'state/account/account.selectors';
import {
    accountCancelSmsRequestRoutine,
    accountCheckPendingSmsRequestsRoutine,
    accountFetchNotificationsRoutine,
    accountSendSmsConfirmationsRoutine,
    accountUpdateNotificationsRoutine
} from 'state/account/account.routines';
import Button from 'ui-kit/button/button';
import { notificationOptions, marketingNotificationOptions } from 'const/options';
import { NotificationsPayload } from 'state/account/account.services';
import { Notifications } from 'types/notifications';
import './contact-preferences.style.scss';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent,
    VerifySmsRequestCanceled
} from './intra-page-items/_profile-update-modal.item';
import FormSelect from 'ui-kit/form-select/form-select';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';
import { SmsTextNumbersToVerify, VerifyPhoneNumberData } from 'types/sms';
import { VerifySmsModal } from 'components/verify-sms-modal';
import { replaceStringWith } from 'util/string';

const NotificationsSection = ({ data }: { data: GatsbyTypes.NotificationsSectionDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const allNotificationPreferences = useSelector(accountNotificationsSelector);
    const smsCancelingReqeust = useSelector(accountSmsCancelingReqeustSelector);
    const verifiedSmsNumbers = useSelector(accountAllSmsNumbersSelector);
    const [notificationsLoaded, setNotificationsLoaded] = useState(false);

    const notificationSections: string[] = useMemo(() => {
        return ['NewScript', 'RefillReminder', 'OrderShipped', 'Marketing'];
    }, []);

    const handleViewTerms = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'text-left',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        eyebrowText={t(`pages.profile.notifications.heading`)}
                        title={t(`pages.profile.notifications.termsAndConditionsTitle`)}
                        body={data.termsAndConditions?.nodes[0]?.relationships?.field_landing_page_content.map(
                            (paragraph: any, index: number) => (
                                <React.Fragment key={`subhead_${index}`}>
                                    {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                                </React.Fragment>
                            )
                        )}
                    />
                ),
                ctas: []
            })
        );
    };

    const resetContactPreferences = useCallback(() => {
        setNotificationsLoaded(false);
        dispatch(
            accountFetchNotificationsRoutine.trigger({
                onSuccess: () => {
                    setNotificationsLoaded(true);
                }
            })
        );
    }, [dispatch]);

    const showConfirmationModal = (
        values: Partial<Notifications>,
        submitProps: FormikHelpers<Partial<Notifications>>
    ) => {
        dispatch(
            openModal({
                showClose: true,
                className: 'text-left',
                onClose: () => {
                    resetContactPreferences();
                },
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t(`pages.profile.notifications.confirmPreferencesTitle`)}
                        body={data.confirmation?.nodes[0]?.relationships?.field_landing_page_content.map(
                            (paragraph: any, index: number) => (
                                <React.Fragment key={`subhead_${index}`}>
                                    {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                                </React.Fragment>
                            )
                        )}
                    />
                ),
                ctas: [
                    {
                        label: t('button.confirm'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(setBusyModal(true));
                            saveNotificationPreferences(values, submitProps);
                        },
                        async: true
                    },
                    {
                        label: t('button.cancel'),
                        variant: 'text',
                        onClick: () => {
                            resetContactPreferences();
                            dispatch(closeModal({}));
                        },
                        className: 'p-4'
                    }
                ]
            })
        );
    };

    useEffect(() => {
        resetContactPreferences();
    }, [resetContactPreferences]);

    const handleCancelSmsVerification = useCallback(() => {
        if (!smsCancelingReqeust) {
            dispatch(
                accountCancelSmsRequestRoutine.trigger({
                    onSuccess: () => {
                        resetContactPreferences();
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: <VerifySmsRequestCanceled />,
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    }, [dispatch, resetContactPreferences, smsCancelingReqeust, t]);

    const handleDisplayVerifySmsModal = useCallback(
        (submitValues: any) => {
            dispatch(
                accountCheckPendingSmsRequestsRoutine.trigger({
                    onSuccess: () => {
                        // dispatch the new verification Modal
                        dispatch(
                            openModal({
                                showClose: false,
                                bodyContent: (
                                    <VerifySmsModal
                                        t={t}
                                        dispatch={dispatch}
                                        valuesToSubmit={submitValues}
                                        resetContactPreferences={resetContactPreferences}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('button.cancel'),
                                        variant: 'text',
                                        onClick: () => {
                                            dispatch(setBusyModal(true));
                                            handleCancelSmsVerification();
                                        },
                                        async: true
                                    }
                                ],
                                backdrop: 'static'
                            })
                        );
                    }
                })
            );
        },
        [dispatch, handleCancelSmsVerification, resetContactPreferences, t]
    );

    const saveNotificationPreferences = useCallback(
        (values: Partial<Notifications>, submitProps: FormikHelpers<Partial<Notifications>>) => {
            let submitValues: any = values;
            let hasPhoneNotification = false;
            let phoneNumbersToVerify: VerifyPhoneNumberData[] = [];

            notificationSections.forEach((sectionKey) => {
                const sectionSelectedValue = submitValues[`${sectionKey}Value`];

                if (sectionSelectedValue) {
                    notificationOptions.forEach((opt) => {
                        if (opt.value !== 'none') {
                            submitValues[`${sectionKey}${opt.value}`] = opt.value === sectionSelectedValue;
                        }
                        if (
                            (sectionSelectedValue === 'Text' && !submitValues[`${sectionKey}TextNumber`]) ||
                            submitValues[`${sectionKey}TextNumber`] === ''
                        ) {
                            submitValues[`${sectionKey}TextNumber`] = submitValues[`${sectionKey}PhoneNumber`];
                        }
                    });
                    delete submitValues[`${sectionKey}Value`];
                }
                if (submitValues[`${sectionKey}Phone`]) {
                    hasPhoneNotification = true;
                }

                const unformattedPhoneNumber = replaceStringWith(
                    submitValues[`${sectionKey}TextNumber`],
                    new RegExp(/[^0-9]/g),
                    ''
                );

                if (
                    submitValues[`${sectionKey}Text`] &&
                    submitValues[`${sectionKey}TextNumber`] !== allNotificationPreferences[`${sectionKey}TextNumber`] &&
                    !verifiedSmsNumbers.includes(unformattedPhoneNumber)
                ) {
                    phoneNumbersToVerify.push({
                        phoneNumber: unformattedPhoneNumber,
                        smsType: `${sectionKey}Text`
                    });
                }
            });

            submitValues.ConsentAutoCalls = hasPhoneNotification;

            // get list of text phone numbers and check if they are different - if different - send for verification
            if (phoneNumbersToVerify.length > 0) {
                let phoneNumObj: SmsTextNumbersToVerify = {};
                phoneNumbersToVerify.filter((value) => {
                    if (phoneNumObj[value.phoneNumber]) {
                        phoneNumObj[value.phoneNumber].push(value.smsType);
                        return false;
                    }
                    phoneNumObj[value.phoneNumber] = [value.smsType];
                    return true;
                });

                dispatch(
                    accountSendSmsConfirmationsRoutine.trigger({
                        phoneNumbers: phoneNumObj,
                        onSuccess: () => {
                            handleDisplayVerifySmsModal(submitValues);
                        }
                    })
                );
            } else {
                dispatch(
                    accountUpdateNotificationsRoutine.trigger({
                        ...submitValues,
                        onSuccess: () => {
                            submitProps.resetForm({ values }); // <-- Changes dirty to false
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <UpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.notifications')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ContactPreferenceUpdateProfile'
                                        }
                                    ]
                                })
                            );
                            resetContactPreferences();
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.notifications')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ContactPreferenceUpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            }
        },
        [
            allNotificationPreferences,
            dispatch,
            handleDisplayVerifySmsModal,
            notificationSections,
            resetContactPreferences,
            t,
            verifiedSmsNumbers
        ]
    );

    const handleFormSubmit = (values: Partial<Notifications>, submitProps: FormikHelpers<Partial<Notifications>>) => {
        // Make sure there is confirmation content, if not, just save as before.
        if (data.confirmation?.nodes[0]) {
            showConfirmationModal(values, submitProps);
        } else {
            saveNotificationPreferences(values, submitProps);
        }
    };

    const getSectionSelectedOption = ({ values, sectionKey }: { values: NotificationsPayload; sectionKey: string }) => {
        let selectedOption = 'none';
        if (sectionKey) {
            if (values[`${sectionKey}Email`]) {
                selectedOption = 'Email';
            } else {
                if (values[`${sectionKey}Phone`]) {
                    selectedOption = 'Phone';
                } else {
                    if (values[`${sectionKey}Text`]) {
                        selectedOption = 'Text';
                    }
                }
            }
        }

        return selectedOption;
    };

    const SectionOptions = ({ formik, sectionKey }: any) => {
        return (
            <div className="notification-form--section mb-4">
                <p className="profile-form-instructions">
                    {t(`pages.profile.notifications.sections.${sectionKey}.title`)}
                </p>
                <p className="ml-3">{t(`pages.profile.notifications.sections.${sectionKey}.heading`)}</p>
                <Row className="d-sm-flex d-block">
                    <Col>
                        <Field
                            name={`${sectionKey}Value`}
                            component={FormSelect}
                            options={sectionKey === 'Marketing' ? marketingNotificationOptions : notificationOptions}
                            value={getSectionSelectedOption({ values: formik.values, sectionKey: sectionKey })}
                            placeholder={t('pages.profile.notifications.labels.preference')}
                            touched={formik.touched[`${sectionKey}Value`]}
                        />
                    </Col>
                    <Col>
                        <Text
                            name={`${sectionKey}EmailAddress`}
                            className={
                                formik.values[`${sectionKey}Value`] === undefined
                                    ? getSectionSelectedOption({ values: formik.values, sectionKey: sectionKey }) !==
                                      'Email'
                                        ? 'd-none'
                                        : ''
                                    : formik.values[`${sectionKey}Value`] !== 'Email'
                                    ? 'd-none'
                                    : ''
                            }
                            label={t('pages.profile.notifications.labels.email')}
                            onChange={formik.handleChange}
                            errors={
                                formik.errors[`${sectionKey}EmailAddress`]
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('pages.profile.messages.labels.subject')
                                      })
                                    : undefined
                            }
                            value={formik.values[`${sectionKey}EmailAddress`]}
                            defaultValue={formik.initialValues[`${sectionKey}EmailAddress`]}
                        />
                        <PhoneNumberText
                            name={`${sectionKey}PhoneNumber`}
                            className={
                                formik.values[`${sectionKey}Value`] === undefined
                                    ? getSectionSelectedOption({ values: formik.values, sectionKey: sectionKey }) !==
                                      'Phone'
                                        ? 'd-none'
                                        : ''
                                    : formik.values[`${sectionKey}Value`] !== 'Phone'
                                    ? 'd-none'
                                    : ''
                            }
                            label={t('pages.profile.notifications.labels.phoneNumber')}
                            onChange={formik.handleChange}
                            errors={
                                formik.errors[`${sectionKey}PhoneNumber`]
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('pages.profile.messages.labels.subject')
                                      })
                                    : undefined
                            }
                            touched={formik.touched[`${sectionKey}PhoneNumber`]}
                            value={formik.values[`${sectionKey}PhoneNumber`]}
                            countryCode={t(`countryCode`)}
                            defaultValue={formik.initialValues[`${sectionKey}PhoneNumber`]}
                        />

                        <PhoneNumberText
                            name={`${sectionKey}TextNumber`}
                            className={
                                formik.values[`${sectionKey}Value`] === undefined
                                    ? getSectionSelectedOption({ values: formik.values, sectionKey: sectionKey }) !==
                                      'Text'
                                        ? 'd-none'
                                        : ''
                                    : formik.values[`${sectionKey}Value`] !== 'Text'
                                    ? 'd-none'
                                    : ''
                            }
                            label={t('pages.profile.notifications.labels.phoneNumber')}
                            onChange={formik.handleChange}
                            errors={
                                formik.errors?.subject
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('pages.profile.messages.labels.subject')
                                      })
                                    : undefined
                            }
                            touched={formik.touched[`${sectionKey}TextNumber`]}
                            value={
                                formik.values[`${sectionKey}TextNumber`]
                                    ? formik.values[`${sectionKey}TextNumber`]
                                    : formik.values[`${sectionKey}PhoneNumber`]
                            }
                            countryCode={t(`countryCode`)}
                            defaultValue={
                                formik.initialValues[`${sectionKey}TextNumber`]
                                    ? formik.initialValues[`${sectionKey}TextNumber`]
                                    : formik.initialValues[`${sectionKey}PhoneNumber`]
                            }
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.notifications.eyebrowText`)}
            title={t(`pages.profile.notifications.title`)}
            heading={t(`pages.profile.notifications.heading`)}
            subhead={data.header?.nodes[0]?.relationships?.field_landing_page_content.map(
                (paragraph: any, index: number) => (
                    <React.Fragment key={`subhead_${index}`}>
                        {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                    </React.Fragment>
                )
            )}
        >
            <LoadingMessage isVisible={!notificationsLoaded} text={t(`pages.profile.notifications.loading`)} />
            {notificationsLoaded && (
                <Container fluid>
                    <Row>
                        <Col className="d-flex flex-column">
                            <div className="ml-3">
                                <Formik<Partial<Notifications>>
                                    onSubmit={(values, submitProps) => {
                                        handleFormSubmit(values, submitProps);
                                    }}
                                    enableReinitialize
                                    initialValues={allNotificationPreferences}
                                >
                                    {(formik: any) => (
                                        <Form id="notification-form" autoComplete="off">
                                            {notificationSections.map((notificationType, index) => {
                                                return (
                                                    <SectionOptions
                                                        formik={formik}
                                                        sectionKey={notificationType}
                                                        values={formik.values}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                            <Row className={`d-flex align-items-center`}>
                                                <Col lg={5} className={'text-left order-2 order-lg-1'}>
                                                    <Button
                                                        className="sm-full md-full"
                                                        disabled={!formik.dirty}
                                                        label={t('pages.profile.notifications.labels.submit')}
                                                        variant="primary"
                                                        type="submit"
                                                        onClick={formik.handleSubmit}
                                                    />
                                                </Col>
                                                <Col className={'text-right order-1 order-lg-1'}>
                                                    {data.termsAndConditions?.nodes[0] && (
                                                        <Button
                                                            className="sm-full md-full"
                                                            label={t(
                                                                'pages.profile.notifications.labels.termsAndConditions'
                                                            )}
                                                            variant="text"
                                                            type="button"
                                                            onClick={handleViewTerms}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
        </ProfileLayout>
    );
};

export default NotificationsSection;

export const query = graphql`
    query NotificationsSectionData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        header: allNodeLandingPage(
            filter: { path: { alias: { eq: "/embedded/profile/contact-preferences-header" } } }
        ) {
            nodes {
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        termsAndConditions: allNodeLandingPage(
            filter: { path: { alias: { eq: "/embedded/profile/contact-preferences-terms-and-conditions" } } }
        ) {
            nodes {
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
        confirmation: allNodeLandingPage(
            filter: { path: { alias: { eq: "/embedded/profile/contact-preferences-confirmation" } } }
        ) {
            nodes {
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
    }
`;
